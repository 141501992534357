<template>
  <v-app>
    <v-card :disabled="error !== ''" width="400" class="mx-auto mt-5">
      <v-card-title> Finish Your Registration </v-card-title>
      <v-card-text>
        <v-form v-model="isValid">
          <v-text-field
            v-model="email"
            :type="'text'"
            label="Email"
            prepend-icon="$mdi-email"
            required
            disabled
          />
          <v-text-field
            v-model="firstName"
            :type="'text'"
            label="First Name"
            prepend-icon="$mdi-face"
            required
            :rules="nameRules"
          />
          <v-text-field
            v-model="lastName"
            :type="'text'"
            label="Last Name"
            prepend-icon="$mdi-face"
            required
            :rules="nameRules"
          />
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            prepend-icon="$mdi-lock"
            :append-icon="showPassword ? '$mdi-eye' : '$mdi-eye-off'"
            required
            :rules="passwordRules"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="success"
          block
          :disabled="error !== '' || !isValid || isDone === true"
          @click="register"
        >
          Finish Registration and Login
        </v-btn>
      </v-card-actions>
      <v-alert v-if="error !== ''" dense text type="error" class="ma-2">
        {{ error }}
      </v-alert>
    </v-card>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { passwordRules } from '@/utils/form-rules'
import { finishUserRegistration } from '@/api/auth'

export default {
  data: () => ({
    showPassword: false,
    password: 'AdminUser4$',
    firstName: 'Jill',
    lastName: 'Smith',
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 2) || 'Must have 2 or more characters',
    ],
    isValid: true,
    isDone: false,
    registrationId: '',
    email: '',
    phone: '555-1212',
    error: '',
    passwordRules: passwordRules,
  }),
  created() {
    if (this.$route.query.id) {
      this.registrationId = this.$route.query.id
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
  },
  methods: {
    ...mapActions('auth', ['setJwt']),
    async register() {
      try {
        const jwt = await finishUserRegistration({
          registrationId: this.registrationId,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
        })

        if (jwt) {
          this.setJwt(jwt)
          this.$router.push({ name: 'home' })
        }
      } catch (error) {
        this.error = error.response.data['error']['message']
      }
    },
  },
}
</script>
